<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'3'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="date-table">
				<div class="title">
					<span>管理员列表</span>
				</div>
				<div class="date-search">
					<el-button type="primary" @click="administratorsAdd">添加</el-button>
				</div>
				<div class="mt-20"></div>
				<el-table :data="tableData.data" border style="width: 100%">
					<el-table-column prop="username" label="用户名" width="250" align="center">
					</el-table-column>
					<el-table-column prop="created_at" label="添加时间" width="350" align="center">
					</el-table-column>
					<el-table-column label="账号状态" width="150" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.display == 1" style="color: red;">禁用</span>
							<span v-if="scope.row.display == 2" style="color: #000;">正常</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button size="mini" type="primary" style="width: 100px"
								@click="undatePassword(scope.$index, scope.row)" v-if="scope.row.id != 1">修改密码
							</el-button>
							<el-button size="mini" type="danger" v-if="scope.row.id != 1" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
							<el-button size="mini" type="danger" @click="disableAdmin(scope.$index, scope.row)"
								v-if="scope.row.display == 2 && scope.row.id != 1">禁用
							</el-button>
							<el-button size="mini" type="success" @click="liftAdmin(scope.$index, scope.row)"
								v-if="scope.row.display == 1 && scope.row.id != 1">解禁
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="bottom-paging">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="1" :page-sizes="[5, 10, 20, 30, 100]" :page-size="num"
						layout="total, prev, pager, next, sizes" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog title="修改密码" :visible.sync="centerDialogVisible" width="600px" center>
			<div class="from-item">
				<span>输入新密码<span class="txt-red">*</span>：</span>
				<div class="from-item-input">
					<input type="password" v-model="fromList.password" placeholder="请输入密码" />
				</div>
			</div>
			<div class="from-item">
				<span>确认新密码<span class="txt-red">*</span>：</span>
				<div class="from-item-input">
					<input type="password" v-model="fromList.andpass" placeholder="请输入密码" />
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="updatePass">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import leftList from "../../components/leftList.vue";
	import topHeader from "../../components/header.vue";
	export default {
		components: {
			leftList,
			topHeader,
		},
		inject: ["reload"],
		data() {
			return {
				centerDialogVisible: false,
				tableData: [],
				isCollapse: false,
				page: 1,
				num: 10,
				fromList: {
					password: "",
					andpass: "",
				},
				amdinInfo: {},
			};
		},
		created() {
			this.getList();
		},
		methods: {
			liftAdmin(index, row) {
				this.$confirm('此操作将解禁此管理员, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$post('user/disable_user', {
						id: row.id
					}).then(res => {
						console.log(res);
						if (res.data.status == 200) {
							this.$notify({
								title: '提示',
								message: '操作成功',
								type: 'success'
							});
							this.reload();
						} else {
							this.$notify({
								title: '提示',
								message: res.data.message,
								type: 'error'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			disableAdmin(index, row) {
				this.$confirm('此操作将禁用此管理员, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$post('user/disable_user', {
						id: row.id
					}).then(res => {
						console.log(res);
						if (res.data.status == 200) {
							this.$notify({
								title: '提示',
								message: '操作成功',
								type: 'success'
							});
							this.reload();
						} else {
							this.$notify({
								title: '提示',
								message: res.data.message,
								type: 'error'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			updatePass() {
				this.$post("user/reset_pwd", {
					password: this.fromList.password,
					login_name: this.amdinInfo.username,
				}).then((res) => {
					if (res.data.status == 200) {
						this.$notify({
							title: "提示",
							message: "修改成功！",
							type: "success",
						});
						this.reload();
					} else {
						this.$notify.error({
							title: "提示",
							message: res.data.message,
						});
					}
				});
			},
			getList() {
				this.$get("user/get_user", {
					page: this.page,
					num: this.num,
				}).then((res) => {
					this.tableData = res.data.data;
				});
			},
			menuitemClick(pushUrl) {
				// console.log(this.$route.path == pushUrl);
				if (this.$route.path == pushUrl) {
					this.reload();
				} else if (pushUrl) {
					this.$router.push(pushUrl);
				} else {
					this.$notify.error({
						title: "提示",
						message: "暂无页面",
					});
					this.reload();
				}
			},
			administratorsAdd() {
				this.$router.push("/administratorsadd");
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.num = val;
				this.getList();
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val;
				this.getList();
			},
			undatePassword(index, row) {
				this.centerDialogVisible = true;
				this.amdinInfo = row;
				console.log(index, row);
			},
			undateRole(index, row) {
				this.amdinInfo = row;
				console.log(index, row);
			},
			handleDelete(index, row) {
				this.$confirm("此操作将永久删除该管理员, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
						center: true,
					})
					.then(() => {
						this.$post("user/del", {
							user_id: row.id,
						}).then((res) => {
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.reload();
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
				console.log(index, row);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.date-table {
			padding: 15px 30px;
			margin-bottom: 20px;

			.cityNumber {
				margin-top: 30px;
				padding-left: 140px;
			}

			.date-search {
				margin-top: 30px;

				.left,
				.right {
					display: inline-block;
				}

				.right {
					width: 900px;
					vertical-align: top;
				}

				.el-button {
					padding: 12px 55px;
				}
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.top-nav {
			padding: 15px;
			border-bottom: 20px solid #f2f2f2;
		}
	}

	.from-item {
		margin-top: 50px;

		.from-item-select {
			display: inline-block;
			margin-left: 30px;
		}

		.from-item-input {
			display: inline-block;
			width: 200px;
			border-bottom: 1px solid #bbb5b5;
			margin-left: 30px;

			input {
				width: 190px;
				border: 0px;
				outline: none;
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
